import VideoCatalogController from './video_catalog_controller';

export default class extends VideoCatalogController {
  static targets = ['videoArea', 'imageArea', 'noImageMsg', 'lotDetailsLink', 'lotList', 'propertyArea',
    'eventDetails', 'property', 'secondaryMedia', 'mediaArea']

  connect() {
    super.connect();

    this.imageTarget.onload = () => {
      const currentHeight = parseInt(this.secondaryMediaTarget.style.minHeight, 10);
      if (!currentHeight || this.imageTarget.height > currentHeight) {
        this.secondaryMediaTarget.style.minHeight = `${this.imageTarget.height}px`;
      }
      this.scrollToCurrentLotRow();
    };
  }

  hideShareModal() {
    $('#event-share-modal').modal('hide');
    $('#share-modal').modal('hide');
  }

  selectLot(e) {
    const event = e;
    const lotRow = event.currentTarget;
    const lotId = lotRow.getAttribute('data-lot-id');
    const previousLotId = this.element.getAttribute('data-lot-id');
    if (lotId !== previousLotId) {
      this.element.setAttribute('data-previous-lot-id', previousLotId);
      this.element.setAttribute('data-lot-id', lotId);
      this.highlightRow(lotRow);
    }

    const previousLotRow = this.getLotRow(previousLotId);
    this.resetLotRowIcons(previousLotRow);

    this.selectVideo(lotRow);

    const propertiesIcon = lotRow.querySelector('.properties-icon');
    const imageIcon = lotRow.querySelector('.image-icon');
    this.imageAreaTarget.hidden = true;
    this.noImageMsgTarget.hidden = true;

    if (imageIcon && (!event.target || !event.target.classList.contains('properties-icon'))) {
      this.selectImage(event);
    } else if (propertiesIcon) {
      this.selectProperties(event);
    } else if (this.secondaryMediaTarget.style.minHeight.trim() !== '') {
      this.noImageMsgTarget.hidden = false;
    }

    this.scrollToCurrentLotRow();

    const lotNumber = lotRow.getAttribute('data-lot-number');
    this.logGoogleAnalytics(lotNumber, lotId);

    const thirdParty = (lotRow.getAttribute('data-third-party') === 'true' && this.videoAreaTarget.hidden === false);
    if (thirdParty) {
      this.videoPlayerTarget.setAttribute('controlsList', 'nodownload');
    } else {
      this.videoPlayerTarget.setAttribute('controlsList', '');
    }
    this.thirdPartyMessageTarget.hidden = !thirdParty;
    this.updateSharingLinks(lotNumber);
    this.element.setAttribute('data-previous-lot-id', lotId);
    this.lotDetailsLinkTarget.href = `/lots/${lotId}`;
  }

  selectVideo(lotRow, autoPlay = true) {
    const videoUrl = lotRow.getAttribute('data-video-url');
    const posterUrl = lotRow.getAttribute('data-poster-url');

    this.propertyAreaTarget.hidden = true;

    const lotId = this.element.getAttribute('data-previous-lot-id');
    const currentLotId = lotRow.getAttribute('data-lot-id');
    this.loadVideo(videoUrl, posterUrl);
    if (autoPlay && lotId !== currentLotId) {
      this.playVideo();
    }
  }

  selectImage(e) {
    const event = e;
    const lotRow = event.currentTarget.closest('.lot-row');
    const lotId = lotRow.getAttribute('data-lot-id');
    const imageUrl = lotRow.getAttribute('data-image-url');

    this.propertyAreaTarget.hidden = true;
    this.imageAreaTarget.hidden = false;

    const propertiesIcon = lotRow.querySelector('.properties-icon');
    if (propertiesIcon) {
      propertiesIcon.hidden = false;

      const imageIcon = lotRow.querySelector('.image-icon');
      if (imageIcon) {
        imageIcon.hidden = true;
      }
    }

    this.displayImage(imageUrl);

    const currentLotId = this.element.getAttribute('data-lot-id');
    if (event.stopImmediatePropagation && currentLotId === lotId && event.target && !event.target.classList.contains('fa-share-alt')) {
      event.stopPropagation();
    }

    this.scrollToCurrentLotRow();
  }

  selectProperties(e) {
    const event = e;
    const lotRow = event.currentTarget.closest('.lot-row');
    const lotId = lotRow.getAttribute('data-lot-id');

    this.imageAreaTarget.hidden = true;
    this.propertyAreaTarget.hidden = false;

    const imageIcon = lotRow.querySelector('.image-icon');
    if (imageIcon) {
      imageIcon.hidden = false;
    }

    this.displayLotProperties(lotId);
    lotRow.querySelector('.properties-icon').hidden = true;

    const currentLotId = this.element.getAttribute('data-lot-id');
    if (currentLotId === lotId && Object.keys(event).length > 1) {
      event.stopPropagation();
    }
  }

  showEventDetails(e) {
    const event = e;
    this.eventDetailsTarget.hidden = false;
    this.lotListTarget.hidden = true;
    this.mediaAreaTarget.hidden = true;

    const detailsLink = event.currentTarget;
    Array.from(detailsLink.parentElement.children).forEach((child) => {
      child.classList.remove('active');
    });

    detailsLink.classList.add('active');
  }

  showLotList(e) {
    const event = e;
    this.eventDetailsTarget.hidden = true;
    this.lotListTarget.hidden = false;
    this.mediaAreaTarget.hidden = false;

    const lotListLink = event.currentTarget;

    Array.from(lotListLink.parentElement.children).forEach((child) => {
      child.classList.remove('active');
    });

    lotListLink.classList.add('active');
  }

  displayLotProperties(lotId) {
    this.propertiesAndUpdatesTarget.innerHTML = '';

    const xhr = new XMLHttpRequest();
    xhr.open('GET', `/video_catalogs/${this.element.getAttribute('data-video-catalog-id')}/lots/${lotId}/lot_properties`);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onreadystatechange = () => {
      this.propertiesAndUpdatesTarget.innerHTML = xhr.responseText;

      const currentHeight = parseInt(this.secondaryMediaTarget.style.minHeight, 10);
      if (!currentHeight || this.propertiesAndUpdatesTarget.clientHeight > currentHeight) {
        this.secondaryMediaTarget.style.minHeight = `${this.propertiesAndUpdatesTarget.clientHeight}px`;
      }

      this.scrollToCurrentLotRow();
    };
    xhr.send();
  }

  scrollToCurrentLotRow() {
    const lotId = this.element.getAttribute('data-lot-id');
    const lotRow = this.getLotRow(lotId);
    if (!lotRow) return;

    const mediaAreaBottom = this.mediaAreaTarget.parentElement.offsetTop + this.mediaAreaTarget.clientHeight;
    const lotRowTop = this.mediaAreaTarget.parentElement.offsetTop + lotRow.offsetTop;
    const lotRowBottom = mediaAreaBottom + (lotRow.offsetTop + lotRow.clientHeight) - window.screen.height;
    const scrollLocation = document.documentElement.scrollTop;

    if (scrollLocation > lotRowTop) {
      window.scroll(0, lotRowTop);
    } else if (scrollLocation < lotRowBottom) {
      window.scroll(0, lotRowBottom);
    }
  }

  getLotRow(lotRowId) {
    return this.lotListTarget.querySelector(`[data-lot-id='${lotRowId}']`);
  }

  resetLotRowIcons(lotRow) {
    if (lotRow != null && lotRow.getAttribute('data-lot-id')) {
      const propertiesIcon = lotRow.querySelector('.properties-icon');
      const imageIcon = lotRow.querySelector('.image-icon');
      if (imageIcon && propertiesIcon) {
        imageIcon.hidden = true;
        propertiesIcon.hidden = false;
      }
    }
  }

  watchLot(e) {
    const lotRow = e.currentTarget.closest('.lot-row');
    const request = new XMLHttpRequest();
    request.open('GET', `/lots/${lotRow.dataset.lotId}/watch`, true);
    request.send();

    lotRow.querySelector('.watch').hidden = true;
    lotRow.querySelector('.unwatch').hidden = false;
  }

  unwatchLot(e) {
    const lotRow = e.currentTarget.closest('.lot-row');
    const request = new XMLHttpRequest();
    request.open('GET', `/lots/${lotRow.dataset.lotId}/unwatch`, true);
    request.send();

    lotRow.querySelector('.watch').hidden = false;
    lotRow.querySelector('.unwatch').hidden = true;
  }
}
