import VideoCatalogController from './video_catalog_controller';

export default class extends VideoCatalogController {
  static targets = ['lotDetailsLink']

  connect() {
    super.connect();
  }

  preventContextMenu(event) {
    event.preventDefault();
  }

  selectLot(event, autoPlay = true) {
    const lotRow = event.currentTarget;
    const lotId = lotRow.getAttribute('data-lot-id');
    const currentLotId = this.element.getAttribute('data-lot-id');

    this.logGoogleAnalytics(lotRow.getAttribute('data-lot-number'), lotId);

    if ((currentLotId !== lotId) || this.videoPlayerTarget.paused) {
      this.element.setAttribute('data-lot-id', lotId);

      const videoUrl = lotRow.getAttribute('data-video-url');
      const posterUrl = lotRow.getAttribute('data-poster-url');
      const imageUrl = lotRow.getAttribute('data-image-url');
      const thirdParty = lotRow.getAttribute('data-third-party') === 'true';
      if (thirdParty) {
        this.videoPlayerTarget.setAttribute('controlsList', 'nodownload');
        this.videoPlayerTarget.addEventListener('contextmenu', this.preventContextMenu);
      } else {
        this.videoPlayerTarget.setAttribute('controlsList', '');
        this.videoPlayerTarget.removeEventListener('contextmenu', this.preventContextMenu);
      }

      this.loadVideo(videoUrl, posterUrl);
      if (autoPlay) {
        this.playVideo();
      }

      this.highlightRow(lotRow);
      this.displayImage(imageUrl);
      this.displayLotProperties(lotId);
      this.thirdPartyMessageTarget.hidden = !thirdParty;
      this.updateSharingLinks(lotRow.dataset.lotNumber);
      this.updateWatchlistLinks(lotRow.dataset.lotId, lotRow.dataset.watching);
      this.lotDetailsLinkTarget.href = `/lots/${lotId}`;
    }
  }

  toggleWatchlistLinks() {
    const lotRow = document.querySelector('#lots .lot-row.active');
    lotRow.dataset.watching = (lotRow.dataset.watching === 'true' ? 'false' : 'true');

    this.watchTarget.hidden = (lotRow.dataset.watching === 'true');
    this.unwatchTarget.hidden = (lotRow.dataset.watching === 'false');
  }

  updateWatchlistLinks(id, watching) {
    if (!this.hasWatchTarget && !this.hasUnwatchTarget) return;

    this.watchTarget.hidden = (watching === 'true');
    this.unwatchTarget.hidden = (watching === 'false');

    this.watchTarget.href = `/lots/${id}/watch`;
    this.unwatchTarget.href = `/lots/${id}/unwatch`;
  }
}
