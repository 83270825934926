import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const recipients = JSON.parse(document.getElementById('email-form').dataset.recipients);
    if (recipients) {
      this.initializeRecipients(recipients);
    }
    this.validationTimer = null;
  }

  addRecipient(event, recipient = '') {
    if (event?.preventDefault) {
      event.preventDefault();
    }

    const template = document.getElementById('recipient-template');
    if (!template) return;

    event.target.insertAdjacentHTML('beforebegin', template.innerHTML);
    const newRow = document.getElementById('add-recipient-button').previousElementSibling;
    if (!newRow) return;

    newRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
    const inputField = newRow.querySelector('input[name*="recipient"]');
    if (!inputField) return;

    inputField.focus();
    inputField.value = recipient;
    inputField.addEventListener('input', this.clearErrors.bind(this));
    inputField.addEventListener('blur', this.validateEmail.bind(this));

    this.updateDeleteButtons();
  }

  clearErrors(event) {
    const inputField = event.target;
    if (inputField.classList.contains('is-invalid')) {
      inputField.classList.remove('is-invalid');
      const recipientRow = inputField.closest('.recipient-row');
      const errorMessage = recipientRow.nextElementSibling;
      if (errorMessage && errorMessage.classList.contains('invalid-feedback')) {
        errorMessage.remove();
      }
    }
  }

  detectComma(event) {
    const inputField = event.target;
    const value = inputField.value;
    if (event.data === ',') {
      inputField.value = value.slice(0, -1);
      const addRecipientButton = document.getElementById('add-recipient-button');
      if (addRecipientButton) {
        this.addRecipient({ target: addRecipientButton });
      }
    } else if (event.inputType === 'insertFromPaste') {
      const recipients = value.split(',').map(email => email.trim()).filter(email => email !== '');
      if (recipients.length > 1) {
        [inputField.value] = recipients;
        recipients.slice(1).forEach((recipient) => { this.addRecipient({ target: document.getElementById('add-recipient-button') }, recipient); });
      }
    }
  }

  initializeRecipients(recipients) {
    const firstRecipientField = this.element.querySelector('input[name="recipients[]"]');
    if (firstRecipientField) {
      [firstRecipientField.value] = recipients;
    }
    recipients.slice(1).forEach((recipient) => { this.addRecipient({ target: document.getElementById('add-recipient-button') }, recipient); });
  }

  removeRecipient(event) {
    event.preventDefault();
    const recipientRow = event.target.closest('.recipient-row');
    const errorMessage = recipientRow.nextElementSibling;
    if (errorMessage && errorMessage.classList.contains('invalid-feedback')) {
      errorMessage.remove();
    }
    recipientRow.remove();
    this.updateDeleteButtons();
  }

  updateDeleteButtons() {
    const deleteButtons = this.element.querySelectorAll('.remove-recipient');
    deleteButtons.forEach((button) => {
      button.hidden = deleteButtons.length <= 1;
    });
  }

  validEmail(email) {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    return emailRegex.test(email);
  }

  validateAndSubmit(event) {
    event.preventDefault();
    const recipientInputs = this.element.querySelectorAll('input[name="recipients[]"]');
    const allValid = Array.from(recipientInputs).every(input => !input.classList.contains('is-invalid') && this.validEmail(input.value));
    if (allValid) {
      const form = event.target.closest('form');
      form.requestSubmit();
      $('[data-target="admin--remote-loading.modal"]').modal('hide');
    }
  }

  validateEmail(event) {
    const input = event.target;

    if (this.validationTimer) {
      clearTimeout(this.validationTimer);
    }
    this.validationTimer = setTimeout(() => {
      if (!document.body.contains(input)) return;

      if (this.validEmail(input.value)) {
        input.classList.remove('is-invalid');
        const recipientRow = input.closest('.recipient-row');
        const errorMessage = recipientRow.nextElementSibling;
        if (errorMessage && errorMessage.classList.contains('invalid-feedback')) {
          errorMessage.remove();
        }
      } else {
        input.classList.add('is-invalid');
        const recipientRow = input.closest('.recipient-row');
        let errorMessage = recipientRow.nextElementSibling;
        if (!errorMessage || !errorMessage.classList.contains('invalid-feedback')) {
          errorMessage = document.createElement('div');
          errorMessage.className = 'invalid-feedback d-block';
          recipientRow.parentNode.insertBefore(errorMessage, input.closest('.recipient-row').nextSibling);
        }
        if (input.value === '') {
          errorMessage.textContent = 'Email address cannot be empty';
        } else {
          errorMessage.textContent = `"${input.value}" is not a valid email address`;
        }
      }
    }, 100);
  }
}
